

import React, { useEffect, useState } from 'react'

import CreateConsultationNew from './CreateConsulltationNew';
import CreateConsultationNewForm from './CreateConsulltationNewform';
import { useDispatch } from 'react-redux';
import { Decryptedid } from '../Util/BcruptEncyptid';
import { useParams } from 'react-router-dom';
import { getdetailsConsultationAction } from '../Redux/Action/ManageconsultationAction';
import EditCreateConsulltationNewOne from './EditCreateConsulltationNewOne';
import EditCreateConsultationNewForm from './EditCreateConsultationNewForm';
import EditCreateConsultationNew from './EditCreateConsultationNew';
import EditConsultationOptions from './EditConsulltationOptions';

export default function EditConsultationmaster() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const dispatch=useDispatch();
  const {id}=useParams();


  var idvalue = Decryptedid(atob(id));




  useEffect(()=>{
dispatch(getdetailsConsultationAction(idvalue)).then(function(data){
    if(data.payload){
        setFormData(data.payload)
    }
})
  },[idvalue])

  const nextStep = () => {
      setStep(step + 1);
    };

  //   const dispatch = useDispatch();
    const prevStep = () => {

      setStep(step - 1);
    };

 

switch(step){
  case 1:


      return (
        Object.keys(formData).length>0 &&<EditCreateConsultationNew  formData={formData} setFormData={setFormData} nextStep={nextStep}  />
      )
  case 2:
      return (
        Object.keys(formData).length>0 &&<EditCreateConsulltationNewOne  formDatavalue={formData} setFormData={setFormData} nextStep={nextStep} idconsultaion={idvalue} prevStep={prevStep} />
      )

      case 3:
        return (
          Object.keys(formData).length>0 &&<EditConsultationOptions  formDatavalue={formData} setFormData={setFormData} nextStep={nextStep} idconsultaion={idvalue} prevStep={prevStep} />
        )



  case 4:
      return (
        Object.keys(formData).length>0 && <EditCreateConsultationNewForm  formData={formData}  prevStep={prevStep} />
      )

default:
  return null;
}
}
