import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RichTextEditor = ({ givenContent, faqdetails ,setFieldValue}) => {
  const [editorState, setEditorState] = useState();

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    let blogContent = JSON.stringify(
      convertToRaw(newEditorState.getCurrentContent())
    );
    setFieldValue(blogContent)

    console.log(newEditorState.getCurrentContent(),"editorStateeditorStateeditorState")
  };

  useEffect(() => {
    if (givenContent && faqdetails && !editorState) {
    
      const contentState = convertFromRaw(JSON.parse(givenContent));
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [givenContent]);


  

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};

export default RichTextEditor;
