import React from "react";
import Sidebar from "./Include/Sidebar";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addSideeffectAction,
  createForum,
  deleteForum,
  deletesideeffectAction,
  getForum,
  getSideEffectAction,
  updateForum,
} from "../Redux/Action/ContaindicationAction";
import { useEffect } from "react";
import { informationdeleteAction, informationlistAction } from "../Redux/Action/ManageconsultationAction";
export default function Faqs() {
  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(informationlistAction())
  },[])


  

  var faqlists=useSelector((state)=>state.ManageConsultation.faqlists);


  return (
    <div>
      <div id="layout-wrapper">
        {/* <!-- ========== Left Sidebar Start ========== --> */}
        <div className="vertical-menu">
          <Sidebar />
        </div>
        {/* <!-- Left Sidebar End --> */}

        {/* <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== --> */}
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              {/* <!-- start page title --> */}
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <span class="small_text">Consultify</span>
                      <div className="d-flex justify-content-center align-items-center">
                        <h4 class="mb-sm-0 font-size-28">FAQs</h4>
                        <p className="total"></p>
                      </div>
                    </div>

                    <div class="page-title-right">
                      {/* <!-- App Search--> */}

                      <img
                        src={require("../assets/images/avatar.svg").default}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              {/* <!-- start search row --> */}
              <div class="row mb-4">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-6">
                  <input
                    name="title"
                    type="text"
                    class="form-control cmn_fields"
                    aria-describedby="emailHelp"
                    placeholder="Search Here"
                    //  onChange={(e) => handleFilter(e, 'message')}
                    //  onKeyDown={(e) => handleFilter(e)}
                    //value = {filter?.title}
                  />
                </div>

                <div className="col-xxl-6">
                  <div className="text-end">
                    <NavLink to="/add-faq">
                      {" "}
                      <button class="btn cre_new">Add</button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end search row --> */}

            <div class="row">
              <div class="col-xl-12">
                <div class="members_tbl">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                        <h4 class="title_text">FAQs</h4>
                      </div>

                      <div class="table-responsive">
                        <table
                          id=""
                          class="table dt-responsive dealers_table nowrap w-100"
                        >
                          <thead>
                            <tr>
                              {/* <th>Entry ID</th> */}
                              <th>S.No </th>
                              <th>Title</th>

                              <th>Assigned To</th>
                              <th>Actions</th>
                            </tr>
                          </thead>

                          <tbody class="td_color">
                          {
                            faqlists?.map(function(object,i){
                              return(
                            <tr>
                              <td>{i+1}</td>
                              <td>{object?.question}</td>
                              <td>{object?.usertype}</td>
                              <td>
                                {" "}
                                <td>
                                  <Link to={"/edit-faq/"+object?._id} className="button edit">
                                    Edit
                                  </Link>
                                  <button className="button delete" onClick={()=>{
                                    dispatch(informationdeleteAction({
                                      id:object?._id
                                    })).then(function(){
                                      dispatch(informationlistAction())
                                    })
                                  }}>
                                    Delete
                                  </button>
                                </td>
                              </td>
                            </tr>
                              )
                            })
                          }
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- container-fluid --> */}
        </div>
      </div>
    </div>
  );
}
