import React, { useCallback, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Sidebar from './Include/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { getSalonListAction } from '../Redux/Action/SalonAction';
import moment from 'moment/moment';
import ReactPaginate from 'react-paginate';
import CryptoJS from 'crypto-js';
import { Encryptedid } from '../Util/BcruptEncyptid';

export default function AllSalons() {
    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(getSalonListAction())
    },[])
    const salonlist=useSelector((state)=>state.Salon.salonlist)
    const navigate=useNavigate();

    var salonprofile =useCallback( (id) => {
        const encodedEncrypted = Encryptedid(id);
        navigate("/Salon-overview/"+encodedEncrypted);
      },[]);
  return (
    <div>
    {/* <!-- Begin page --> */}
     <div id="layout-wrapper">

         {/* <!-- ========== Left Sidebar Start ========== --> */}
         <div className="vertical-menu">
        <Sidebar />
         </div>
         {/* <!-- Left Sidebar End --> */}

         

         {/* <!-- ============================================================== -->
         <!-- Start right Content here -->
         <!-- ============================================================== --> */}
         <div class="main-content">

             <div class="page-content">
                 <div class="container-fluid">

                     {/* <!-- start page title --> */}
                     <div class="row">
                         <div class="col-12">
                             
                             <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div>
                                       <span class="small_text">Consultify</span>
                                       <div className='d-flex justify-content-center align-items-center'>
                                            <h4 class="mb-sm-0 font-size-28">All Salons</h4>
                                       <p className='total'>{salonlist.length}</p>
                                       </div>
                                      </div>
                                  <div class="page-title-right">
                                 {/* <!-- App Search--> */}
                                        <img src={require('../assets/images/avatar.svg').default} />
                                   </div>
                               </div>
                         </div>
                     </div>
                       {/* <!-- end page title --> */}
                       

                        {/* <!-- start search row --> */}
                     <div class="row mb-4">
                         <div class="col-xxl-4 col-lg-3">
                         <input type="text" class="form-control cmn_fields" id="" aria-describedby="emailHelp" placeholder="Search Here" />
                           </div>    

                         <div className="col-xxl-2 col-lg-3">  
                                 <div class="form-group">
                                 <select class="form-control cmn_fields" id="exampleFormControlSelect1">
                                 <option>Type</option>
                                 <option>2</option>
                                 <option>3</option>
                                 <option>4</option>
                                 <option>5</option>
                                 </select>
                               </div>
                               </div>
                                   
                            <div className="col-xxl-2 col-lg-3"> 
                                   <div class="form-group">
                                 <select class="form-control cmn_fields" id="exampleFormControlSelect2">
                                 <option>Last Active</option>
                                 <option>2</option>
                                 <option>3</option>
                                 <option>4</option>
                                 <option>5</option>
                                 </select>
                               </div>
                               </div>
                                   
                            <div className="col-xxl-2 col-lg-3"> 
                                    <div class="form-group">
                                 <select class="form-control cmn_fields" id="exampleFormControlSelect3">
                                 <option>Purchases</option>
                                 <option>2</option>
                                 <option>3</option>
                                 <option>4</option>
                                 <option>5</option>
                                 </select>
                               </div>
                           </div>
                           
                           
                         </div>
                     </div>
                     {/* <!-- end search row --> */}

                     <div class="row">
                         <div class="col-xl-12">
                             <div class="members_tbl">

                                 <div class="card">
                                     <div class="card-body">
                                   <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                                             <h4 class="title_text">Salons</h4>
                                         </div>
                                     <div class="table-responsive">
                                             <table id="" class="table dt-responsive dealers_table nowrap w-100">
                                                 <thead>
                                                 <tr>
                                                     <th>Salon ID</th>
                                                     <th>Salon Name</th>
                                                     <th>Contact</th>
                                                     <th>Date Joined <img class="filt_arrow" src={require('../assets/images/greydownarrow.svg').default}  /></th>
                                                     <th>Contact Email </th>
                                                     <th>Contact Phone</th>
                                                     <th>City </th>
                                                     <th>Last Active <img class="filt_arrow" src={require('../assets/images/greydownarrow.svg').default}  /></th>
                                                     <th>Clients</th>
                                                     <th>Consultations</th>
                                                   </tr>
                                                 </thead>
                                                <tbody class="td_color">
                                                {
                                                    salonlist.map(function(salonobject,i){
                                                        return (
                                                            <tr key={i}>
                                                     <td>{salonobject.memberNo}</td>
                                                     <td><span className='cursor-pointer' onClick={()=>{
                                                        salonprofile(salonobject._id)
                                                     }}>{salonobject.salonname} </span></td>
                                                     <td className='cursor-pointer' onClick={()=>{
                                                        salonprofile(salonobject._id)
                                                     }}>{salonobject.firstname+" "+salonobject.lastname}</td>
                                                     <td>{moment(salonobject.updatedAt).format("DD/MM/YYYY")}</td>
                                                     <td>{salonobject.email}</td>
                                                     <td>{salonobject.personal_phone}  </td>
                                                     
                                                     <td>{salonobject.address}</td>
                                                     <td>{moment(salonobject.lastLogin).format("DD/MM/YYYY")}</td>
                                                     <td>---</td>
                                                     <td>---</td>
                                                   </tr>
                                                        )
                                                    })
                                                }
                                                 
                                                  
                                                 </tbody>
                                             </table>
                                         </div>
         
                                     </div>
                                 </div>
                             </div>
                         </div>

                     
                     </div>
                     
                   </div>
                   {/* <!-- container-fluid --> */}
             </div>
             {/* <!-- End Page-content --> */}

     
             

         </div>
         {/* <!-- end main content--> */}

   </div>
  )
}
